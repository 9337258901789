<template>
  <div>
    <el-dialog :title="text"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               v-dialogDrag
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="120px"
               ref="formRef"
               :rules="rules">
        <el-row :gutter="10">
          <el-col :span="24"
                  v-if="text == '拒绝原因'">
            <el-form-item prop="reason"
                          label="原因">
              <el-input clearable
                        placeholder="请输入原因"
                        style="width:100%"
                        type="textarea"
                        :rows="12"
                        v-model="form.reason" />
            </el-form-item>
          </el-col>

          <div v-else>
            <el-col :span="24">
              <el-form-item label="地区"
                            prop="paper_district_id">
                <el-select v-model="form.paper_district_id"
                           style="width:100%"
                           clearable
                           placeholder="请选择">
                  <el-option v-for="item in disList"
                             :key="item.paper_district_id"
                             :label="item.paper_district_name"
                             :value="item.paper_district_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="年级"
                            prop="grade_id">
                <el-select v-model="form.grade_id"
                           style="width:100%"
                           clearable
                           placeholder="请选择">
                  <el-option v-for="item in gradeList"
                             :key="item.grade_id"
                             :label="item.grade_name"
                             :value="item.grade_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="24">
              <el-form-item label="是否为著名试卷">
                <el-radio-group v-model="form.is_famous">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="是否为真题试卷">
                <el-radio-group v-model="form.is_true_paper">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="是否为备考试卷">
                <el-radio-group v-model="form.is_for_senior">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
          </div>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        reason: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        source_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        paper_district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      text: '',
      gradeList: [],
      disList: []
    }
  },

  methods: {
    getList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get'
      }).then(res => {
        this.disList = res.data
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$parent.submit(this.form)
        this.handleClose()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

.download {
  div {
    text-align: center;
    margin-bottom: 30px;
  }
  font-size: 18px;
  font-weight: 500;
  color: black;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog__body {
  padding-bottom: 0;
}
::v-deep .el-button--medium {
  width: 90px;
  height: 36px;
  border-radius: 28px;
}
</style>